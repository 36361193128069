<template>
  <div v-if="isLoaded">
    <student-header />
    <div class="user-flow-logo">
      <div class="user-flow">
        <div class="row">
          <student-profile-overview
            v-if="step === 1"
            :handleSubmit="handleSubmit"
            :width="width"
            :progress="userDetails.currentProgress * 2"
          />
          <student-star
            :userDetails="userDetails"
            @update-width="updateWidth"
            :handleSubmit="handleSubmit"
            v-if="step === 2"
          />
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center">
        <router-link to="/" class="text-decoration-none pink-color font-weight-bold mb-5">Go to Dashboard <img src="~@/assets/hus.png" alt="" srcset="" class="home_img"></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import StudentProfileOverview from '../../components/student/StudentProfileOverview.vue';
import { mapState } from 'vuex';
import StudentHeader from '../../components/student/StudentHeader.vue';
import StudentStar from '../../components/student/StudentStar.vue';

export default {
  data: function () {
    return {
      step: 1,
      width: '100%',
      // width: '100%',
      userDetails: {},
    };
  },
  components: {
    StudentProfileOverview,
    StudentHeader,
    StudentStar,
  },
  mounted() {
    var vm = this;
    vm.$store.dispatch('setSignupData', 'Student');
  },
  computed: {
    ...mapState(['user']),
    isLoaded() {
      var vm = this;
      var result = false;
      if (this.user.userId !== null) {
        var userInfo = JSON.parse(JSON.stringify(vm.$store.state.user));
        vm.userDetails = userInfo;
      }
      if (
        vm.userDetails.isCourses ||
        vm.userDetails.isCompetencies ||
        vm.userDetails.isTalent ||
        vm.userDetails.isExp
      ) {
        vm.step = 2;
      }
      return true;
    },
  },
  methods: {
    handleSubmit: function () {
      this.step++;
    },
    updateWidth(val) {
      this.width = val;
    },
  },
};
</script>

<style>
.btn-outline {
  border: 1px solid #d22d89;
  color: #d22d89;
  padding: 0.4rem 4rem;
  font-size: 16px;
  font-weight: 600;
}
.bottom_flex{
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: end;
  margin-top: 10px;
}
.home_img{
  max-width: 20px;
}

@media (min-width: 499px) {
  /* .congratulations-button{
    max-width: 430px;
    margin:auto;
  } */
}
</style>
<style scoped>
.user-flow {
  max-width: 100% !important;
}
</style>
