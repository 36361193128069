<template>
  <div>
    <div class="main-wrapper">
      <h3 class="main-title" @click="toggleCollapse(id)">
        Semester {{ id }}
        <i
          class="fa"
          :class="{
            'fa-caret-down': !activeCollapse.includes(id),
            'fa-caret-right': activeCollapse.includes(id),
          }"
        ></i>
      </h3>
      <div class="experience-container d-flex align-items-center mb-3">
        <div class="" v-show="value.semesterCourseCount[id].length === 0">
          I did not get any ECTS points this semester
        </div>
        <div
          v-for="courseId in value.semesterCourseCount[id]"
          :key="courseId"
          :class="{
            'dark-course': courseId % 2 === 0,
            'light-course': courseId % 2 !== 0,
            'show, collapse': activeCollapse.includes(id),
          }"
          class="col-md-12 left-container"
        >
          <div class="d-flex align-items-center form-box">
            <div class="form_no">
              <span class="form-id"
              >#{{ value.semesterCourseCount[id].indexOf(courseId) + 1 }}</span
            >
            </div>
            <div class="form-fields">
            <div class="form-group text-left one w-30" >
              <label for="">Educational institution</label>
              <select
                class="form-control text-secondary"
                v-model="value.educationHistory[semId][courseId].institution"
                @change="getCurrentUniversityData()"
                required
              >
                <option
                  value="null"
                  disabled
                  v-if="universities.universities.length !== 1"
                >
                  Select from list
                </option>
                <option
                  v-for="(institute, index) in universities.universities"
                  :key="index"
                  :value="{
                    uid: institute.id,
                    id: institute.university_id,
                    name: institute.university_name,
                  }"
                >
                  {{ institute.university_name }}
                </option>
              </select>
            </div>
            <div class="form-group one text-left w-30 margin-sem">
              <label for="">Study programme</label>
              <div
                class="pj-loading"
                v-if="!universityLoaded"
              >
                <i class="fas fa-circle-notch fa-spin"></i>
              </div>
              <div
                class="form-group text-left"
                v-if="universityLoaded"
              >
                <autocomplete
                  style="height: 44px; font-size: 14px"
                  :search="searchArea"
                  required
                  placeholder="Search and select"
                  aria-label="Search and select"
                  class="hard-skills"
                  :get-result-value="getResultValue"
                  @submit="setAreaForUser($event, semId, courseId)"
                  v-bind:default-value="value.educationHistory[semId][courseId].area"
                ></autocomplete>
              </div>
            </div>
            <div class="form-group two text-left w-30">
              <label for="">Course title</label>
              <input
                type="text"
                v-model="value.educationHistory[semId][courseId].courseTitle"
                class="form-control form-control-lg"
                required
              />
            </div>
            <div class="form-group three text-left w-18">
              <label for="">ECTS</label>
              <input
                type="text"
                v-model="value.educationHistory[semId][courseId].ects"
                class="form-control form-control-lg"
                placeholder="E.g. 7.5"
                required
              />
            </div>
            <div class="form-group four text-left w-18">
              <label for="">Year</label>
              <select
                class="form-control"
                v-model="value.educationHistory[semId][courseId].year"
              >
                <option value="" disabled selected>Select</option>
                <option
                  v-for="(year, index) in yearsArray"
                  :key="index"
                  :value="year"
                  :selected="index === currentYearIndex"
                >
                  {{ year }}
                </option>
              </select>
            </div>
            <div class="form-group four text-left w-18">
              <label for="">Semester</label>
              <select
                class="form-control"
                v-model="value.educationHistory[semId][courseId].semester"
              >
                <option value="" selected disabled>Select</option>
                <option
                  v-for="(item, index) in semestersArray"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>
            </div>
            <div class="form-group five text-left w-18">
              <label for="">Result</label>
              <select
                class="form-control"
                v-model="value.educationHistory[semId][courseId].result"
              >
                <option value="" selected disabled>Select</option>
                <option
                  v-for="(result, index) in resultsArray"
                  :key="index"
                  :value="result"
                >
                  {{ result }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-actions">
            <div class="button-wrap">
              <button
                class="btn button-pink mb-3"
                @click="handleDelete(id, courseId)"
              >
                Remove
              </button>
              <button
                class="btn btn-link pink-color"
                v-if="!value.educationHistory[semId][courseId].hasAssignment"
                @click="addAssignment(id, courseId)"
              >
                Add assignment
              </button>
              <button
                class="btn btn-link pink-color"
                v-else
                @click="removeAssignment(id, courseId)"
              >
                Remove assignment
              </button>
            <!-- </div> -->
          </div>
          </div>
          </div>
         
            <div
              class="add-assignment"
              v-if="value.educationHistory[semId][courseId].hasAssignment"
            >
              <div class="d-flex mobile-flex">
                <div class="w-100">
                  <div class="form-group text-left">
                    <label for="">Assignment title</label>
                    <input
                      type="text"
                      v-model="
                        value.educationHistory[semId][courseId].assignmentTitle
                      "
                      class="form-control form-control-lg"
                      required
                    />
                  </div>
                  <div class="form-group text-left">
                    <label for="">Assignment result</label>
                    <select
                      class="form-control"
                      v-model="
                        value.educationHistory[semId][courseId].assignmentResult
                      "
                    >
                      <option value="" selected disabled>Select</option>
                      <option
                        v-for="(result, index) in resultsArray"
                        :key="index"
                        :value="result"
                      >
                        {{ result }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group text-left w-100">
                  <label for="">Description</label>
                  <plain-text-editor
                    placeholder="Describe your assignment."
                    limit="500"
                    v-model="
                      value.educationHistory[semId][courseId].description
                    "
                  ></plain-text-editor>
                </div>
              </div>
            </div>
          </div>
        <!-- </div> -->
      </div>
    </div>
    <div class="row">
      <div class="button-container col-md-12 centered-text">
        <button class="btn add-button" @click.prevent="addCount(id)">+</button>
      </div>
    </div>
  </div>
</template>

<script>
import Autocomplete from '@trevoreyre/autocomplete-vue';

import '@trevoreyre/autocomplete-vue/dist/style.css';
import { HTTP } from '../../../axios/axios';
import Vue from 'vue';
import PlainTextEditor from '../../plainTextEditor.vue';

// import plainTextEditor from '../../plainTextEditor.vue';
export default {
  // components: { plainTextEditor },
  name: 'semesterItem',
  props: ['id', 'value', 'semId', 'handleDelete', 'universities', 'isBachelor'],
  components: { Autocomplete, PlainTextEditor },
  data() {
    return {
      semestersArray: ['Spring', 'Fall'],
      universityLoaded: false,
      universityData: {},
      activeCollapse: [],
      resultsArray: [
        'Passed',
        'Not Passed',
        'No Result',
        '12',
        '10',
        '7',
        '4',
        '02',
        '00',
        '-3',
      ],
      yearsArray: [],
      // semesters: [{name: 'spring', id: 0}, {name: 'fall', id: 1}]
      // semesterCourseCount: {
      //   1: 1,
      //   2: 1,
      //   3: 1,
      //   4: 1,
      //   5: 1,
      //   6: 1,
      //   7: 1,
      //   8: 1,
      //   9: 1,
      //   10: 1,
      // },
    };
  },
  created() {
    const currentYear = new Date().getFullYear();
    for (let i = currentYear - 5; i <= currentYear + 5; i++) {
      this.yearsArray.push(i);
    }
  },
  mounted: function () {
    this.$store.dispatch('getUniversityData');
    if (this.value.institution.uid) {
      this.getCurrentUniversityData();
      this.loading = false;
    }
  },
  computed: {
    currentYearIndex() {
      const currentYear = new Date().getFullYear();
      return this.yearsArray.indexOf(currentYear);
    },
  },
  methods: {
    addCount(id) {
      // this.semesterCourseCount[id] = this.semesterCourseCount[id] + 1;
      this.$emit('add-course', id);
    },
    addAssignment(id, courseId) {
      Vue.set(this.value.educationHistory[id][courseId], 'hasAssignment', true);
    },
    removeAssignment(id, courseId) {
      Vue.set(
        this.value.educationHistory[id][courseId],
        'hasAssignment',
        false
      );
    },
    async getCurrentUniversityData() {
      this.value.secondaryArea = null;
      this.value.area = null;
      // this.universityLoaded = true;
      try {
        const res = await HTTP.get(
          // `getUniversityDataListing/${this.value.bachelorInstitution.uid}`
          `getUniversityDataListing/${this.value.institution.uid}`
        );
        if (res.status == 200) {
          // no idea wtf this data is now. Random naming with random data is what we have here
          this.universityLoaded = true;
          this.universityData = res.data.payload;
        }
      } catch (error) {
        console.log('err');
      }
    },
    searchArea(input) {
      const vm = this;
      this.value.customProgramme = true;
      if (input.length < 1) {
        return [];
      }
      return this.universityData.programmes.filter((area) => {
        return area.programme_name.toLowerCase().includes(input.toLowerCase());
      });
    },
    getResultValue(result) {
      // this.value.customProgramme = false;
      return result.programme_name;
    },
    setAreaForUser(area, semId, courseId) {
      this.value.educationHistory[semId][courseId].area = area.programme_name;
    },
    toggleCollapse(id) {
      if (this.activeCollapse.includes(id)) {
        const index = this.activeCollapse.indexOf(id);
        this.activeCollapse.splice(index, 1);
      } else {
        this.activeCollapse.push(id);
      }
    },
  },
};
</script>

<style scoped>
/* .experience-container {
  background: #00000008;
  flex-direction: column;
  width: 100%;
} */
.btn-link {
  text-decoration: none !important;
}
.add-button {
  font-size: 34px;
  color: white;
  background: #5a2871;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
}
.main-title {
  text-align: left;
  padding: 0px 30px;
}
.bottom-container {
  padding: 0px 30px 30px;
}
.main-wrapper {
  margin-top: 50px;
}
.experience-container {
  background: #00000008;
  flex-direction: column;
  width: 100%;
}
.button-pink {
  background-color: #bc1e73;
  color: white;
}
.left-container {
  /* padding-right: 30px; */
  text-align: left;
  padding: 3rem;
}
.right-container {
  margin-left: -30px;
}
.form-box {
  column-gap: 10px;
}
.input-container {
  width: 19%;
}
.form-id {
  color: #5a2871;
  font-size: 18px;
  font-weight: 600;
  /* width: 4%; */
}
.centered-text {
  text-align: center;
  text-align: -webkit-center;
}
@media (max-width: 767px) {
  .experience-container {
    flex-direction: column;
    /* padding: 20px 0px !important; */
  }
  .left-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .form-box {
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 0;
  }
  .input-container {
    width: 48%;
  }
}
.dark-course {
  background-color: #f6f6f6;
}
.light-course {
  background-color: #fff;
}
/* .one {
  width: 55%;
}
.two {
  width: 30%;
}
.three {
  width: 21%;
}
.four {
  width: 21%;
}
.five {
  width: 30%;
} */
input,
select {
  height: 44px !important;
}
.addBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.button-wrap {
  display: flex;
  flex-direction: column;
}
.mobile-flex {
  column-gap: 10px;
}
.form_no{
  width: 3%;
}
.form-fields{
  display: flex;
    align-items: center;
    width: 76%;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 15px;
}
.form-actions{
  width: 21%;
}
 .w-30{
  width: 30%;
}
.w-18{
  width: 18%;
}
.add-assignment{
  padding-left: 38px;
}
@media (max-width: 767px) {
  .one {
    width: 100%;
  }
  .two {
    width: 100%;
  }
  .three {
    width: 47%;
  }
  .four {
    width: 47%;
  }
  .five {
    width: 47%;
  }
  .addBox {
    flex-direction: column;
  }
  .mobile-flex {
    margin-top: 10px;
  }
  .form-fields{
    width: 100%;
  }
  .form-actions{
    width: 100%;
    margin-top: 30px;
  }
  .form_no{
    width: 100%;
    margin-bottom: 10px;
  }
  .main-title {
  padding: 0px;
}
.add-assignment{
  padding-left: 0px;
}
}
</style>
<style>
.autocomplete-input {
  height: 47px !important;
  font-size: 14px;
}
.margin-sem {
  margin-top: 11px;
}
</style>
