<template>
  <div class="experience-box">
    <div class="inner-box">
      <h1 class="text-uppercase common-text">YOUR EXPERIENCE</h1>
      <rising-star-progress-bar
        :currentValue="progress"
      ></rising-star-progress-bar>
      <h4 class="welcome-desc font-weight-normal">
        Tell us a bit more about your student activites and work experience to
        make your profile stand out. You can always edit and/or add your work
        experience.
      </h4>
    </div>
    <div class="pj-loading" v-show="!count">
      <i class="fas fa-circle-notch fa-spin"></i>
    </div>
    <work-experience-item
      v-for="(item, index) in count"
      :key="item"
      :id="item"
      :currentIndex="index"
      v-model="value"
    ></work-experience-item>
    <div class="button-container mt-4">
      <button class="btn add-button" @click="addCount">+</button>
    </div>
    <div class="alert alert-danger mt-3" v-if="errorText">
      {{ errorText }}
    </div>
    <div class="text-right mt-5 inner-box">
      <div>
        <button
          class="btn btn-outline btn-new-outline Talent-Experience-Skip w-200"
          @click.prevent="handleSkip"
        >
          Skip
        </button>
        <!-- <button
          class="btn btn-primary Talent-Experience-Next w-200"
          @click.prevent="handleSubmit('work experience')"
        >
          Next
        </button> -->
        <button
          class="btn btn-primary Talent-Experience-Next w-200"
          @click.prevent="validateForm"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import RisingStarProgressBar from '../../RisingStarProgressBar.vue';
import WorkExperienceItem from './WorkExperienceItem.vue';

export default {
  components: { WorkExperienceItem, RisingStarProgressBar },
  props: ['value', 'handleSubmit', 'progress', 'handleBack', 'handleSkip'],
  data() {
    return {
      count: null,
      errorText: null,
    };
  },
  watch: {
    value() {
      this.count = Object.keys(this.value.workExperiences);
    },
  },
  mounted() {
    if(!this.master.industries) {
      this.$store.dispatch('getIndustries')
    }
    this.count = Object.keys(this.value.workExperiences);
  },
  computed:{
    ...mapState(['master']),
  },
  methods: {
    addCount() {
      const newElem = Math.max(...Object.keys(this.value.workExperiences)) + 1
      this.value.workExperiences[newElem.toString()] = {}
      this.count.push(newElem.toString())
      // this.value.workExperiences = Object.assign(this.value.workExperiences, {
      //   [this.count.length + 1]: {},
      // });
      // // this.value.workExperiences = {...this.value.workExperiences, }
      // this.count.push((this.count.length + 1));
    },
    validateForm() {
      this.errorText = null;
      this.count.forEach((element) => {
        const currentObject = this.value.workExperiences[element];
        if (
          !currentObject.description ||
          !currentObject.title ||
          !currentObject.employmentType ||
          !currentObject.company ||
          !currentObject.industry ||
          !currentObject.locationType ||
          !currentObject.startMonth ||
          !currentObject.startYear ||
          (!currentObject.workingHere &&
            (!currentObject.endMonth || !currentObject.endYear))
        ) {
          this.errorText = 'Please fill all the fields to proceed';
        } 
      });
      if(!this.errorText){
        this.$emit('submit-form', 'work experience')
      }
    },
  },
};
</script>

<style scoped>
.add-button {
  font-size: 34px;
  color: white;
  background: #5a2871;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
}
.button-container {
  display: flex;
  justify-content: center;
}
.experience-box {
  /* padding: 50px 0px; */
}
.inner-box {
  padding: 0px 30px;
}
.btn-new-outline {
  padding: 0.375rem 0.75rem !important;
  width: 200px;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .experience-box {
    /* padding: 50px 0px 30px 0px !important; */
  }
  .btn-new-outline {
    width: 120px;
  }
  .w-200 {
    /* width: 120px; */
    margin-top: 20px;
  }
  .inner-box {
    padding: 0px 0px;
  }
}
</style>
