<template>
  <div class="" v-show="isLoaded">
    <div
      class="pj-loading"
      v-if="hardSkillsData.length === 0 && coreSkillsData.length === 0"
    >
      <i class="fas fa-circle-notch fa-spin"></i>
    </div>
    <div class="" v-else>
      <h1 class="text-uppercase common-text">YOUR COMPETENCIES</h1>
      <rising-star-progress-bar
        :currentValue="progress"
      ></rising-star-progress-bar>
      <h4 class="welcome-desc font-weight-normal container-narrow mx-auto">
        Tell us a bit more about your competencies. Add 5 soft and 5 hard skills
        in a prioritized order.
      </h4>
      <div class="row">
        <div class="col-md-6">
          <h4 class="text-left mt-5">Soft skills</h4>
          <competence-core-skills
            v-for="(item, index) in skillArray"
            :skill="item"
            :id="index + 1"
            v-bind:key="`core-${item}`"
            :placeholder="coreSkillTitleArray"
            @custom-core-skill="addNewCoreSkill"
            v-model="userDetails.coreSkills"
            :skillData="coreSkillsData"
          ></competence-core-skills>
        </div>
        <div class="col-md-6">
          <h4 class="text-left mt-5">Hard skills</h4>
          <competence-hard-skills
            v-for="(item, index) in skillArray"
            v-bind:key="`hard-${item}`"
            :skill="item"
            @custom-hard-skill="addNewHardSkill"
            :placeholder="hardSkillTitleArray"
            :id="index + 1"
            v-model="userDetails.hardSkills"
            :skillData="hardSkillsData"
          >
          </competence-hard-skills>
        </div>
      </div>
      <div class="alert alert-danger mt-3" v-if="errorText">
        {{ errorText }}
      </div>
      <!-- <div class="d-flex justify-content-between mt-5"> -->
      <!-- <button class="btn btn-link Explorer-YourStudyBackground-Back" @click.prevent="handleBack">
          <i class="fas fa-chevron-left"></i> Back
        </button> -->
      <div class="text-right mt-4">
        <button
          class="btn btn-outline btn-new-outline mr-2 RisingStar-Competencies-Skip w-200"
          @click.prevent="handleSkip"
        >
          Skip
        </button>
        <button
          class="btn btn-primary RisingStar-Competencies-Next w-200"
          @click="validateSkills"
        >
          Next
        </button>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CompetenceHardSkills from './CompetenceHardSkills.vue';
import CompetenceCoreSkills from './CompetenceCoreSkills.vue';
import RisingStarProgressBar from '../../RisingStarProgressBar.vue';
import { HTTP } from '../../../axios/axios';

export default {
  components: {
    CompetenceHardSkills,
    CompetenceCoreSkills,
    RisingStarProgressBar,
  },
  props: ['progress', 'handleBack', 'handleSkip'],
  data() {
    return {
      userDetails: {},
      hardSkillsData: [],
      coreSkillsData: [],
      skillArray: [
        'skill_one',
        'skill_two',
        'skill_three',
        'skill_four',
        'skill_five',
      ],
      coreSkillTitleArray: [
        'E.g. Critical thinking',
        'E.g. Problem-solving',
        'E.g. Adaptability',
        'E.g. Teamwork',
        'E.g. Leadership',
      ],
      hardSkillTitleArray: [
        'E.g. UI/UX design',
        'E.g. HTML/CSS/JS',
        'E.g. Business and data analysis',
        'E.g. Social Media',
        'E.g. Project management',
      ],
      errorText: '',
      customSkills: {
        softSkills: [],
        hardSkills: [],
      },
    };
  },
  async created() {
    try {
      const res = await HTTP.get('getSkills');
      this.hardSkillsData = res.data.data.payload.hardskills;
      this.coreSkillsData = res.data.data.payload.softskills;
    } catch (error) {
      console.log('err');
    }
  },
  computed: {
    ...mapState(['user']),
    isLoaded() {
      var vm = this;
      var result = false;
      if (this.user.userId !== null) {
        var userInfo = JSON.parse(JSON.stringify(vm.$store.state.user));
        vm.userDetails = userInfo;
      }
      return true;
    },
  },
  methods: {

    addNewHardSkill(skill) {
      this.customSkills.hardSkills.push(skill);
    },
    addNewCoreSkill(skill) {
      this.customSkills.softSkills.push(skill);
    },
    validateSkills() {
      this.errorText = '';
      this.testObject(this.userDetails.hardSkills);
      this.testObject(this.userDetails.coreSkills);
      if (!this.errorText) {
        // this.updateUserDetails();
        this.$emit(
          'handle-submit',
          'competencies',
          this.userDetails.hardSkills,
          this.userDetails.coreSkills
        );
        if (
          this.customSkills.hardSkills.length > 0 ||
          this.customSkills.softSkills.length > 0
        ) {
          this.updateCustomSkills();
        }
      }
    },
    updateCustomSkills() {
      try {
        const res = HTTP.post('addSkills', { ...this.customSkills });
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    testObject(obj) {
      if (typeof obj == typeof undefined) {
        this.errorText = 'Please fill all fields to proceed';
        return;
      }
      for (let key in obj) {
        if (obj[key] === '') {
          this.errorText = 'Please fill all fields to proceed';
        }
      }
    },
  },
};
</script>

<style>
.btn-new-outline {
  padding: 0.375rem 0.75rem !important;
  width: 200px;
  margin-right: 10px;
}
@media (max-width: 767px) {
  .btn-new-outline {
    width: 120px;
  }
  .w-200 {
    width: 120px;
    margin-top: 20px;
  }
}
</style>
