<template>
  <div class="col-12 user-form">
    <div>
      <ul
        class="d-flex justify-content-between align-items-center bg-grey overview-header text-white"
      >
        <li
          class="text-light cursor-pointer flex-column"
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ active_tab: activeTab === index, border_right: index != 3 }"
          @click="activeTab = index"
        >
          <h4
            class="text-light"
            :class="{ active: activeTab === index }"
            data-toggle="tab"
          >
            {{ tab }}
          </h4>
          <span class="tab-percentage">
            <i
              v-if="
                userDetails.risingStarStepsCompleted &&
                userDetails.risingStarStepsCompleted.includes(index)
              "
              class="fa fa-check"
            ></i>
            <span v-else>{{ tabPercentages[index] }}%</span>
          </span>
          <div
            data-v-9a01a2da=""
            v-if="activeTab === 0"
            class="arrow-up-1 d-none"
          ></div>
          <div
            data-v-9a01a2da=""
            v-if="activeTab === 1"
            class="arrow-up-2 d-none"
          ></div>
          <div
            data-v-9a01a2da=""
            v-if="activeTab === 2"
            class="arrow-up-3 d-none"
          ></div>
          <div
            data-v-9a01a2da=""
            v-if="activeTab === 3"
            class="arrow-up-4 d-none"
          ></div>
        </li>
      </ul>
      <div class="tab-content">
        <!-- <div
          class="tab-pane"
          v-for="(tab, index) in tabs"
          :key="index"
          :id="'tab-' + index"
          :class="{
            active: activeTab === index,
            'user-container': isActive
          }"
        > -->
        <div class="user-container">
          <courses
            v-model="userDetails"
            :errorText="errorText"
            :handleSubmit="handleFormSubmit"
            @file-selected="onFileSelected"
            :isUploading="isUploading"
            :handleSkip="handleSkip"
            @remove-file="removeUploadedFile"
            v-if="activeTab === 0"
            :progress="userDetails.currentProgress * 2"
          />
          <competencies
            @handle-submit="handleFormSubmit"
            v-if="activeTab === 1"
            :handleSkip="handleSkip"
            :handleBack="handleBack"
            :progress="userDetails.currentProgress * 2"
          />
          <work-experience
            v-model="userDetails"
            @submit-form="handleFormSubmit"
            v-if="activeTab === 2"
            :handleSkip="handleSkip"
            :handleBack="handleBack"
            :progress="userDetails.currentProgress * 2"
          />
          <talent-bio
            v-model="userDetails"
            :errorText="errorText"
            :handleSkip="handleSkip"
            :handleBack="handleBack"
            :handleSubmit="handleFormSubmit"
            v-if="activeTab === 3"
            :progress="userDetails.currentProgress * 2"
          />
          <congratulations-rising-star
            :handleTalent="handleTalent"
            v-if="activeTab === 4"
          ></congratulations-rising-star>
          <congratulations-talent
            v-if="activeTab === 5"
          ></congratulations-talent>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/storage';
import ProgressBar from '../ProgressBar.vue';
import Competencies from './rising-star/Competencies.vue';
import Courses from './rising-star/Courses.vue';
import TalentBio from './rising-star/TalentBio.vue';
import WorkExperience from './rising-star/WorkExperience.vue';
import CongratulationsRisingStar from './rising-star/CongratulationsRisingStar.vue';
import CongratulationsTalent from './rising-star/CongratulationsTalent.vue';
export default {
  name: 'StudentProfileProgress',
  props: ['userDetails', 'handleSubmit'],
  data() {
    return {
      tabs: [
        'Study Background',
        'Competencies',
        'Work experience',
        'Talent bio',
      ],
      errorText: '',
      activeTab: 0, // always 0
      tabPercentages: [30, 20, 30, 20],
      file: null,
      isUploading: false,
      count: null,
      totalWidth: 0,
    };
  },
  components: {
    Courses,
    TalentBio,
    Competencies,
    WorkExperience,
    CongratulationsRisingStar,
    CongratulationsTalent,
  },
  watch: {
    activeTab(newValue, oldValue) {
      if (newValue !== oldValue) {
        window.scrollTo(0, 0);
      }
    },
  },
  created() {
    window.addEventListener('resize', (this.totalWidth = window.innerWidth));
    // this.totalWidth = window.innerWidth;
  },
  destroyed() {
    window.removeEventListener('resize', (this.totalWidth = window.innerWidth));
  },
  mounted() {
    if (this.activeTab === 0) {
      this.$emit('update-width', '100%');
    }
    let counter = 0
    if (!this.userDetails.isCourses) {
      this.activeTab = 0;
    } else if (!this.userDetails.isCompetencies) {
      this.activeTab = 1;
      counter++
    } else if (!this.userDetails.isExp) {
      this.activeTab = 2;
      counter++
    } else {
      this.activeTab = 3;
      counter++
    }
    if(counter > 0){
      this.userDetails.startedTalent = true;
      this.$store.dispatch('updateUserDetails', this.userDetails);
    }
  },
  updated() {
    if (
      this.userDetails.currentProgress >= 50 &&
      this.userDetails.currentProgress <= 50 &&
      !this.userDetails.startedTalent
    ) {
      this.activeTab = 4;
    } else if (
      this.userDetails.currentProgress === 100 &&
      this.userDetails.startedTalent
    ) {
      this.activeTab = 5;
    }
  },
  computed: {
    isActive() {
      return this.activeTab === 0 || this.activeTab == 2 ? false : true;
    },
  },
  methods: {
    async removeUploadedFile() {
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(
        `uploads/${this.userDetails.transcriptName}`
      );
      await fileRef.delete();
      this.userDetails.hasUploadedTranscript = false;
      this.userDetails.transcriptName = '';
      this.$store.dispatch('updateUserDetails', this.userDetails);
    },
    onFileSelected(file) {
      this.file = file;
      this.uploadFile();
    },
    async uploadFile() {
      if (!this.file) {
        return;
      }
      if (this.file.size > 20 * 1024 * 1024) {
        alert('File size exceeds 20 MB');
        return;
      }
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`uploads/${this.file.name}`);
      this.isUploading = true;
      await fileRef.put(this.file);
      this.userDetails.hasUploadedTranscript = true;
      this.userDetails.transcriptName = this.file.name;
      // this.handleFormSubmit('courses');
      this.isUploading = false;
    },
    // handleCompetencies(type, hardSkills, softSkills){
    // },
    handleFormSubmit(step, ...rest) {
      const [hardSkills, coreSkills] = rest;
      const vm = this;
      switch (step) {
        case 'talent bio':
          vm.userDetails.risingStarStepsCompleted = [
            ...vm.userDetails.risingStarStepsCompleted,
            3,
          ];
          // vm.userDetails.risingStarPoints =
          //   vm.userDetails.risingStarPoints + 20;
          if (!vm.userDetails.isTalent) {
            vm.userDetails.currentProgress =
              vm.userDetails.currentProgress + 20;
            vm.userDetails.isTalent = true;
          }
          vm.$store.dispatch('updateUserDetails', vm.userDetails);
          if (!this.userDetails.isCourses) {
            this.activeTab = 0;
          } else if (!this.userDetails.isCompetencies) {
            this.activeTab = 1;
          } else if (!this.userDetails.isExp) {
            this.activeTab = 2;
          }
          break;
        case 'courses':
          vm.activeTab = 1;
          vm.userDetails.risingStarStepsCompleted = [
            ...vm.userDetails.risingStarStepsCompleted,
            0,
          ];
          // vm.userDetails.risingStarPoints =
          //   vm.userDetails.risingStarPoints + 30;
          if (!vm.userDetails.isCourses) {
            vm.userDetails.currentProgress =
              vm.userDetails.currentProgress + 30;
            vm.userDetails.isCourses = true;
          }
          console.log(vm.userDetails);
          vm.$store.dispatch('updateUserDetails', vm.userDetails);
          break;
        case 'competencies':
          vm.activeTab = 2;
          vm.userDetails.risingStarStepsCompleted = [
            ...vm.userDetails.risingStarStepsCompleted,
            1,
          ];
          // vm.userDetails.risingStarPoints =
          //   vm.userDetails.risingStarPoints + 20;
          if (!vm.userDetails.isCompetencies) {
            vm.userDetails.currentProgress =
              vm.userDetails.currentProgress + 20;
            vm.userDetails.isCompetencies = true;
          }
          vm.$store.dispatch('updateUserDetails', {
            ...vm.userDetails,
            hardSkills,
            coreSkills,
          });
          break;
        case 'work experience':
          vm.activeTab = 3;
          vm.userDetails.risingStarStepsCompleted = [
            ...vm.userDetails.risingStarStepsCompleted,
            2,
          ];
          // vm.userDetails.risingStarPoints =
          //   vm.userDetails.risingStarPoints + 30;
          if (!vm.userDetails.isExp) {
            vm.userDetails.currentProgress =
              vm.userDetails.currentProgress + 30;
            vm.userDetails.isExp = true;
          }
          vm.$store.dispatch('updateUserDetails', vm.userDetails);
          // vm.activeTab = 3;
          break;
        // case 'work experience':
        //   vm.activeTab = 3;
        //   vm.userDetails.risingStarStepsCompleted.push(2);
        //   vm.$store.dispatch('updateUserDetails', vm.userDetails);
        //   break;
        default:
          break;
      }
    },
    handleTalent() {
      if (!this.userDetails.isCourses) {
        this.activeTab = 0;
      } else if (!this.userDetails.isCompetencies) {
        this.activeTab = 1;
      } else if (!this.userDetails.isExp) {
        this.activeTab = 2;
      } else {
        this.activeTab = 3;
      }
      this.userDetails.startedTalent = true;
      this.$store.dispatch('updateUserDetails', this.userDetails);
    },
    handleSkip() {
      if (this.activeTab === 3) {
        this.activeTab = 0;
        return;
      }
      this.activeTab += 1;
    },
    handleBack() {
      if (this.activeTab === 0) return;
      this.activeTab -= 1;
    },
  },
};
</script>

<style scoped>
.overview-header li {
  padding: 20px;
  position: relative;
  flex: 1; /* set equal width for all tabs */
  display: flex; /* enable flexbox layout */
  align-items: center; /* vertically center content */
  justify-content: space-between; /* evenly distribute space between elements */
}

.border_right {
  height: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.3); /* add vertical line */
  /* margin-right: 20px; add spacing between tabs */
}

.active_tab h4,
.active_tab .tab-percentage {
  color: #fff; /* change text color */
}

.active_tab:before {
  background: rgba(255, 255, 255, 0.2); /* add light color overlay */
  content: '';
  height: 100%;
  position: absolute;
  z-index: 1111;
  width: 100%;
  top: 0;
  left: 0;
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
}

.arrow-up-1 {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #7d4585; /* match color of active tab */
  margin: auto;
  margin-top: 20px;
  position: absolute;
  left: 46%;
  bottom: -19px; /* position arrow below tab */
}
.arrow-up-2 {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #8b5086; /* match color of active tab */
  margin: auto;
  margin-top: 20px;
  position: absolute;
  left: 46%;
  bottom: -19px; /* position arrow below tab */
}
.arrow-up-3 {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #955085; /* match color of active tab */
  margin: auto;
  margin-top: 20px;
  position: absolute;
  left: 46%;
  bottom: -19px; /* position arrow below tab */
}
.arrow-up-4 {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #aa5287; /* match color of active tab */
  margin: auto;
  margin-top: 20px;
  position: absolute;
  left: 46%;
  bottom: -19px; /* position arrow below tab */
}
.bg-grey {
  /* background-color: #dbdbdb; */
  background: transparent linear-gradient(270deg, #bc1e73 0%, #5a2871 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}
.overview-header {
  margin-bottom: 0;
  padding: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.overview-header li {
  padding: 20px;
  position: relative;
}
.overview-header .active_tab:before {
  background: #fff;
  content: '';
  height: 100%;
  position: absolute;
  z-index: 1111;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.15;
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
}
.overview-header .active_tab:first-child:before {
  border-top-left-radius: 10px;
}
.overview-header .active_tab:last-child:before {
  border-top-right-radius: 10px;
}
.active_tab .arrow-up-1 {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #764d84;
  opacity: 1;
  margin: auto;
  background: rgba(255, 255, 255, 0.2); /* add light color overlay */
  margin-top: 20px;
  position: absolute;
  left: 46%;
  display: block !important;
}
.active_tab .arrow-up-2 {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  background: rgba(255, 255, 255, 0.2); /* add light color overlay */
  border-right: 10px solid transparent;
  border-top: 20px solid #8b5086;
  opacity: 1;
  margin: auto;
  margin-top: 20px;
  position: absolute;
  left: 46%;
  display: block !important;
}
.active_tab .arrow-up-3 {
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2); /* add light color overlay */
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #955085;
  opacity: 1;
  margin: auto;
  margin-top: 20px;
  position: absolute;
  left: 46%;
  display: block !important;
}
.active_tab .arrow-up-4 {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  background: rgba(255, 255, 255, 0.2); /* add light color overlay */
  border-top: 20px solid #aa5287;
  opacity: 1;
  margin: auto;
  margin-top: 20px;
  position: absolute;
  left: 46%;
  display: block !important;
}
ul {
  list-style: none;
}
.border-right {
  height: 100%;
}
.active-tab {
  background-color: #7d4585;
}
.user-container {
  padding: 50px 30px 30px 30px !important;
}
@media (max-width: 767px) {
  .overview-header {
    display: none !important;
  }
  .user-container {
    padding: 50px 0px 30px 0px !important;
  }
}
</style>
<style>
@media (max-width: 767px) {
  #app #app-master #page {
    background-image: none !important;
  }
  .user-flow-logo {
    padding: 0px;
    margin-top: 20px;
  }
  .common-text {
    font-size: 24px;
    margin-bottom: 50px;
  }
  .progress-spacing {
    margin: 25px 0px 50px !important;
    width: 100%;
    padding: 0px 20px;
  }
}
</style>
