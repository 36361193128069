<template>
  <div class="experience-container d-flex align-items-center p-4" :class="{'dark-bg' : id % 2 === 0, 'light-bg' : id % 2 != 0}">
    <div class="left-container" :class="Object.keys(this.value.workExperiences).indexOf(id.toString()) === 0 ? 'col-md-12' : 'col-md-11'" >
      
      <div class="d-flex align-items-center form-box">
        <span class="form-id">#{{ currentIndex +1 }}</span>
        <div class="form-group input-container text-left w-30">
          <label for="">Title</label>
          <input
            type="text"
            v-model="value.workExperiences[id].title"
            class="form-control form-control-lg"
            placeholder="E.g. Student Assistant"
            autofocus
            required
          />
        </div>
        <div class="form-group input-container text-left">
          <label for="">Employment type</label>
          <select
            class="form-control"
            v-model="value.workExperiences[id].employmentType"
          >
            <option value="" selected disabled>Select</option>
            <option
              v-for="(item, index) in employmentType"
              :key="index"
              :value="item.id"
            >
              {{ item.value }}
            </option>
          </select>
        </div>
        <div class="form-group input-container text-left">
          <label for="">Company/organisation</label>
          <input
            type="text"
            v-model="value.workExperiences[id].company"
            class="form-control form-control-lg"
            placeholder="E.g. Excelerate"
            autofocus
            required
          />
        </div>
        <div class="form-group input-container text-left">
          <label for="">Location type</label>
          <select
            class="form-control"
            v-model="value.workExperiences[id].locationType"
          >
            <option value="" selected disabled>Select</option>
            <option
              v-for="(item, index) in locationType"
              :key="index"
              :value="item.id"
            >
              {{ item.value }}
            </option>
          </select>
        </div>
        <div class="form-group input-container text-left">
          <label for="">Industry</label>
          <select
            class="form-control"
            v-model="value.workExperiences[id].industry"
          >
            <option value="" selected disabled>Select</option>
            <option
              v-for="(role, index) in master.industries"
              :key="index"
              :value="role.industry_id"
            >
              {{ role.industry }}
            </option>
          </select>
        </div>
      </div>
      <div class="d-flex align-items-center form-box">
        <div
          class="form-group custom-control custom-switch input-container text-left w-30"
        >
          <p>I am currently working here</p>
          <label class="switch">
            <input
              type="checkbox"
              @change="updateWorkingHere($event, id)"
              v-model="value.workExperiences[id].workingHere"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="form-group input-container text-left">
          <label for="">Start date month</label>
          <select
            class="form-control"
            v-model="value.workExperiences[id].startMonth"
          >
            <option value="" selected disabled>Select</option>
            <option
              v-for="(item, index) in months"
              :key="index"
              :value="item.id"
            >
              {{ item.value }}
            </option>
          </select>
        </div>
        <div class="form-group input-container text-left">
          <label for="">Start date year</label>
          <select
            class="form-control"
            required
            v-model="value.workExperiences[id].startYear"
          >
            <option value="" selected disabled>Select</option>
            <option
              v-for="(item, index) in years"
              :key="index"
              :value="item"
            >
              {{ item }}
            </option>
          </select>
        </div>
        <div
          class="form-group input-container text-left"
          v-if="!value.workExperiences[id].workingHere"
        >
          <label for="">End date month</label>
          <select
            class="form-control"
            required
            v-model="value.workExperiences[id].endMonth"
          >
            <option value="" selected disabled>Select</option>
            <option
              v-for="(item, index) in months"
              :key="index"
              :value="item.id"
            >
              {{ item.value }}
            </option>
          </select>
        </div>
        <div
          class="form-group input-container text-left"
          v-if="!value.workExperiences[id].workingHere"
        >
          <label for="">End date year</label>
          <select
            class="form-control"
            required
            v-model="value.workExperiences[id].endYear"
          >
            <option value="" selected disabled>Select</option>
            <option
              v-for="(item, index) in years"
              :key="index"
              :value="item"
            >
              {{ item }}
            </option>
          </select>
        </div>
      </div>
      <div class="d-flex align-items-center flex-grow-1">
        <div class="form-group text-left w-100">
          <label for="">Description</label>
          <plain-text-editor
            placeholder="Describe your job, responsibilities, and role(s) here."
            v-model="value.workExperiences[id].description"
          ></plain-text-editor>
        </div>
      </div>
    </div>
    <div class="col-md-1 right-container" v-if="Object.keys(this.value.workExperiences).indexOf(id.toString()) !== 0">
      <button class="btn button-pink" @click="handleDelete(id)">Remove</button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import plainTextEditor from '../../plainTextEditor.vue';
export default {
  components: { plainTextEditor },
  name: 'workExperienceItem',
  data() {
    return {
      employmentType: [
        { id: 7, value: 'Apprenticeship' },
        { id: 5, value: 'Contract' },
        { id: 4, value: 'Freelance' },
        { id: 1, value: 'Full-time' },
        { id: 6, value: 'Internship' },
        { id: 2, value: 'Part-time' },
        { id: 3, value: 'Self-Employed' },
        { id: 8, value: 'Seasonal' },
      ],
      locationType: [
        { id: 2, value: 'Hybrid' },
        { id: 1, value: 'On-site' },
        { id: 3, value: 'Remote' },
      ],
      months: [
        { id: 1, value: 'January' },
        { id: 2, value: 'February' },
        { id: 3, value: 'March' },
        { id: 4, value: 'April' },
        { id: 5, value: 'May' },
        { id: 6, value: 'June' },
        { id: 7, value: 'July' },
        { id: 8, value: 'August' },
        { id: 9, value: 'September' },
        { id: 10, value: 'October' },
        { id: 11, value: 'November' },
        { id: 12, value: 'December' },
      ],
      years: [
        2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023
      ]
    };
  },
  props: ['id', 'value', 'currentIndex'],
  computed: {
    ...mapState(['master'])
  },
  methods: {
    handleDelete(id) {
      let experiences = this.value.workExperiences
      delete experiences[id]
      this.$store.dispatch('updateUserDetails', {...this.value, [this.value.workExperiences]: experiences});
    },

    //had to force an update, vue is funny sometimes
    updateWorkingHere(event, id){
      this.value.workExperiences[id].workingHere = event.target.checked
            this.$nextTick(() => {
        this.$forceUpdate();
      });

    }
  },
};
</script>

<style scoped>
.experience-container {
  width: 100%;
  /* margin-top: 50px; */
}
.dark-bg {
  background: #fff;
}
.light-bg {
  background: #F6F6F6;
}
.left-container {
  padding-right: 30px;
  text-align: left;
}
.right-container {
  margin-left: -30px;
}
.form-box {
  column-gap: 10px;
}
.input-container {
  width: 19%;
}
.form-id {
  color: #5a2871;
  font-size: 18px;
  font-weight: 600;
}
.w-30 {
  width: 30% !important;
}
@media (max-width: 767px) {
  .experience-container {
    flex-direction: column;
    padding: 20px 0px !important;
    margin-top: 30px;
  }
  .right-container {
  margin-left: 0;
}
  .left-container {
    padding-right: 15px;
  }
  .form-box {
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 0;
  }
  .input-container {
    width: 48%;
  }
  .w-30 {
  width: 100% !important;
}
.custom-switch {
  padding-left: 0;
  margin-top: 10px;
}
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #3aa948;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3aa948;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.custom-switch p {
  color: #512871!important;
  margin: 0px 0px 7px!important;
  font-weight: 500!important;
  margin-bottom: 0;
}
.custom-switch {
  column-gap: 10px;
}

input, select{
  height: 44px!important;
}

/* label > p{
  color: #5A2871!important;
} */
</style>
