<template>
  <div class="rising-box">
    <h1 class="text-uppercase common-text">CONGRATULATIONS</h1>
    <p>Your profile level is now</p>
    <h1 class="banner-header">RISING STAR</h1>
    <img
      src="~@/assets/arrow-big-purple.png"
      alt="search icon"
      class="m-26"
      srcset=""
    />
    <h4 class="black font-28">
      You have unlocked
    </h4>
    <h4 class="pink">Thesis Collaboration Matching</h4>
    <h4 class="welcome-desc black font-weight-normal container-narrow mx-auto mb-5">
      You can now upload your thesis topic / project proposal so we can match you with relevant companies.
      <br><br>
      Ready to stand out from the crowd? Fill in your work experience and profile bio to upgrade your profile to the next level and stand out.
    </h4>

    <rising-star-progress-bar :currentValue="100"/>
    <div>
<button class="btn w-430 mt-5 btn-primary RisingStar-Congratulations-Next" @click="handleTalent">Stand out with a Talent profile</button>
</div>
<div>
<router-link to="/" class="btn w-430 mb-5 mt-2 btn-outline-purple RisingStar-Congratulations-Dashboard">Go to Rising Star dashboard</router-link>
  </div>
  </div>
</template>

<script>
import RisingStarProgressBar from '../../RisingStarProgressBar.vue';
export default {
  components: { RisingStarProgressBar },
  name: 'CongratulationsRisingStar',
  props: ['handleTalent']
};
</script>

<style scoped>
.banner-header {
  color: #8b2472;
  font-size: 54px;
  font-weight: bold;
}
.m-26 {
  margin-top: 26px;
  margin-bottom: 30px;
}
.font-28{
 font-size: 28px;
 font-weight: bold;
 margin-bottom: 0;
}
.pink{
color: #BC1E73; 
}
.btn-outline-purple{
  border: 2px solid #5A2871;
  color: #5A2871;
}
/* .rising-box{
  padding: 0px 30px;
} */
.welcome-desc{
  margin-top: 25px;
}
.w-430{
  width: 430px;
}
/* .common-texts{
  font-size: 24px;
} */
@media(max-width: 767px) {
  /* .rising-box{
    padding: 0px 0px 0px 0px !important;
  } */
  .welcome-desc{
  margin-bottom: 60px!important;
}

  .w-430{
  width: 100%;
}
}
</style>
