<template>
  <div class="col-12 user-form">
    <div
      class="d-flex justify-content-around align-items-center bg-grey overview-header text-white mb-5 hide-mobile"
    >
      <div class="flex-column">
        <h4 class="">Study Background</h4>
        <span>30%</span>
      </div>
      <div class="flex-column">
        <h4 class="">Competencies</h4>
        <span>20%</span>
      </div>
      <div class="flex-column">
        <h4 class="">Work experience</h4>
        <span>30%</span>
      </div>
      <div class="flex-column">
        <h4 class="">Talent bio</h4>
        <span>20%</span>
      </div>
    </div>
    <div class="spacing">
      <h1 class="text-uppercase mobile-bottom-space">PROFILE COMPLETION OVERVIEW</h1>
      <rising-star-progress-bar :currentValue="0" />
      <!-- setting the progress to 0 manually to remove any issues with the rendering on colored icons -->
      <h4 class="welcome-desc font-weight-normal container-narrow mx-auto">
        Fill in more information about yourself to unlock more features and
        benefits on Excelerate.
      </h4>
      <div class="parent_box">
      <div class="d-flex text-left mobile-flex  mt-4">
        <img
          src="~@/assets/arrow-big-purple.png"
          alt=""

          srcset=""
        />
        <div class="">
          <h3 class="purple-color">Rising Star</h3>
          <ul class="text-left pl-0">
            <li>
              <i class="fa fa-check purple-color" aria-hidden="true"></i> Unlock
              Excelerate Thesis Collaboration Matching
            </li>
            <li>
              <i class="fa fa-check purple-color" aria-hidden="true"></i> Match
              with companies in your industry
            </li>
            <li>
              <i class="fa fa-check purple-color" aria-hidden="true"></i> Upload
              thesis topic / project proposal
            </li>
          </ul>
        </div>
      </div>
      <div class="d-flex text-left mobile-flex mt-4">
        <img src="~@/assets/star-big-purple.png" alt="" srcset="" />
        <div class="">
          <h3 class="pink-color">Talent</h3>
          <ul class="text-left pl-0">
            <li>
              <i class="fa fa-check pink-color" aria-hidden="true"></i> Unlock
              Excelerate Career Matching
            </li>
            <li>
              <i class="fa fa-check pink-color" aria-hidden="true"></i> Triple
              thesis collaboration chances
            </li>
            <li>
              <i class="fa fa-check pink-color" aria-hidden="true"></i> Boost
              profile to stand out
            </li>
          </ul>
        </div>
      </div>
      </div>
      <div class="text-right">
        <button
          class="btn btn-primary RisingStar-ProfileOverview-Next mt-5 w-200"
          v-on:click="handleSubmit"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RisingStarProgressBar from '../RisingStarProgressBar.vue';
export default {
  name: 'StudentProfileProgress',
  props: ['userDetails', 'handleSubmit', 'progress'],
  components: { RisingStarProgressBar },
};
</script>
<style>
.user-flow-logo {
  padding: 60px 0 40px 0;
  margin-top: 35px;
}
.user-flow {
  border-radius: 10px;
}
</style>
<style scoped>
.bg-grey {
  background-color: #dbdbdb;
  opacity: 1;
}
.overview-header {
  height: 100px;
  border-radius: 10px 10px 0 0;
}
ul {
  list-style: none;
}
ul li {
  /* max-width: 300px; */
  /* text-overflow: wrap; */
}
.spacing {
  padding: 50px 30px 30px 30px !important;
}
.mobile-flex {
    justify-content: start;
    column-gap: 40px;
    align-items: center;
}
.mobile-flex img {
    width: 150px;
    height: 150px;
    object-fit: contain;
}
.parent_box{
  width: 50%;
  margin: auto;
}
@media (max-width: 767px) {
  .hide-mobile {
    display: none !important;
  }
  .parent_box{
  width: 100%;
}
  .mobile-flex{
    justify-content: space-between;
    column-gap: 10px;
    margin-top: 40px;
  }
  .mobile-flex img{
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  .mobile-bottom-space{
    margin-bottom: 60px;
  }
  .spacing {
  padding: 50px 0px 30px 0px !important;
}
}
</style>
