<template>
  <div class="rising-box">
    <h1 class="text-uppercase black common-text">CONGRATULATIONS</h1>
    <p>Your profile level is now</p>
    <h1 class="banner-header">TALENT</h1>
    <img
      src="~@/assets/star-big-purple.png"
      alt="search icon"
      class="m-26"
      srcset=""
    />
    <h4 class="black font-28">
      You can now unlock
    </h4>
    <h4 class="pink">Excelerate Career Matching</h4>
    <router-link class="btn btn-primary w-200 Talent-Congratulations-Unlock" to="/user/student-talent/unlock">Unlock</router-link>
    <h4 class="welcome-desc black font-weight-normal container-narrow mx-auto">
     You can now unlock . Excelerate Career Matching You have now tripled your chances of finding a thesis collaboration - and perhaps even your first job.
      <br><br>
      <strong class="text-center">Get started</strong>
      <br>
     Upload your thesis topic / project proposal and we match you with relevant companies.
    </h4>
    <rising-star-progress-bar :currentValue="200" />
    <div>
<router-link class="btn mt-5 btn-primary w-430 Talent-Congratulations-UploadProject" to="/collaboration/new">Upload thesis topic / project proposal</router-link>
</div>
<div>
<router-link to="/" class="btn mb-5 mt-2 w-430 btn-outline-purple Talent-Congratulations-Dashboard">Go to Talent dashboard</router-link>
 </div>
  </div>
</template>

<script>
import RisingStarProgressBar from '../../RisingStarProgressBar.vue';
export default {
  components: { RisingStarProgressBar },
  name: 'CongratulationsTalent',
  props: ['handleTalent']
};
</script>

<style scoped>
.banner-header {
  color: #BC1E73;
  font-size: 54px;
  font-weight: bold;
}
.m-26 {
  margin-top: 26px;
  margin-bottom: 30px;
}
.font-28{
 font-size: 28px;
 font-weight: bold;
 margin-bottom: 0;
}
.pink{
color: #BC1E73; 
}
.btn-outline-purple{
  border: 2px solid #5A2871;
  color: #5A2871;
}
.w-430{
  width: 430px;
}
/* 
 */
.welcome-desc{
  margin-top: 25px;
  margin-bottom: 50px;
}
@media(max-width: 767px) {
  /* .rising-box{
    padding: 50px 0px 30px 0px !important;
  } */
  .w-430{
  width: 100%;
}
}
</style>

