<template>
  <div class="study-wrapper">
    <h1 class="text-uppercase black mobile-text">YOUR STUDY BACKGROUND</h1>
    <rising-star-progress-bar :currentValue="progress" />
    <h4 class="welcome-desc font-weight-normal black container-narrow mx-auto">
      Tell us a bit more about your study background.
    </h4>
    <div class="" v-if="!isBachelor">
      <h3 class="mt-5 black">
        <span class="counter">1</span> Your Bachelor's degree
      </h3>
      <h4 class="welcome-desc font-weight-normal mt-2 black">
        Find and select your Bachelor's degree.
      </h4>
      <div class="user-form-wizard">
        <div class="form-group text-left container-narrow mx-auto">
          <label>Your educational institution? *</label>
          <select
            class="form-control text-secondary"
            v-model="value.bachelorInstitution"
            @change="getCurrentUniversityData()"
            required
          >
            <option
              value="null"
              disabled
              v-if="universities.universities.length !== 1"
            >
              Select from list
            </option>
            <option
              v-for="(institute, index) in universities.universities"
              :key="index"
              :value="{
                uid: institute.id,
                id: institute.university_id,
                name: institute.university_name,
              }"
            >
              {{ institute.university_name }}
            </option>
            <!-- <option value="other">Other</option> -->
          </select>
        </div>
        <div
          class="pj-loading"
          v-show="
            !universityLoaded &&
            value.bachelorInstitution &&
            value.bachelorInstitution.name !== 'Other'
          "
        >
          <i class="fas fa-circle-notch fa-spin"></i>
        </div>

        <div
          class="form-group text-left container-narrow mx-auto"
          v-if="
            value.bachelorInstitution &&
            universityLoaded &&
            value.bachelorInstitution.name != 'Other'
          "
        >
          <label>Your Bachelor's degree? *</label>
          <autocomplete
            :search="searchArea"
            required
            placeholder="Search and select"
            aria-label="Search and select"
            class="hard-skills"
            :get-result-value="getResultValue"
            @submit="setBachelorAreaForUser"
            v-bind="{ 'default-value': value.bachelorArea }"
          ></autocomplete>
        </div>
        <div
          class="form-group text-left container-narrow mx-auto"
          v-if="
            value.hasBachelorSubProgramme &&
            value.bachelorInstitution.name === 'Roskilde University'
          "
        >
          <label>Specialization course #1? *</label>
          <autocomplete
            :search="searchSubProgramme"
            required
            placeholder="Search and select"
            aria-label="Search and select"
            class="hard-skills"
            :get-result-value="getSubProgrammeValue"
            @submit="setFirstSpecialization"
            v-bind:default-value="value.bachelorSpecializationCourseOne"
          ></autocomplete>
        </div>
        <div
          class="form-group text-left container-narrow mx-auto"
          v-if="
            value.hasBachelorSubProgramme &&
            value.bachelorInstitution.name === 'Roskilde University'
          "
        >
          <label>Specialization course #2? *</label>
          <autocomplete
            :search="searchSubProgramme"
            required
            placeholder="Search and select"
            aria-label="Search and select"
            class="hard-skills"
            :get-result-value="getSubProgrammeValue"
            @submit="setSecondSpecialization"
            v-bind:default-value="value.bachelorSpecializationCourseTwo"
          ></autocomplete>
        </div>
        <div class="" v-if="value.bachelorInstitution && value.bachelorInstitution.name == 'Other'">
          <div class="form-group text-left container-narrow mx-auto">
            <label for="">Name of educational institution</label>
            <input
              type="text"
              class="form-control form-control-lg"
              v-model="value.customBachelorInstitutionName"
              placeholder="Enter the name of your educational institution"
            />
          </div>
          <div class="form-group text-left container-narrow mx-auto">
            <label for="">Study program</label>
            <input
              type="text"
              class="form-control form-control-lg"
              v-model="value.customBachelorStudyProgram"
              placeholder="Enter the name of your study program"
            />
          </div>
        </div>
        <div class="form-group text-left container-narrow mx-auto">
          <label>Which year did you graduate? *</label>
          <select
            v-model="value.bachelorGraduatingYear"
            class="form-control"
            required
          >
            <option value="null" disabled>Select from list</option>
            <option v-for="(year, index) in years" :key="index">
              {{ year }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <h3 class="mt-5 black">
      <span v-if="isBachelor" class="counter">1</span>
      <span v-else class="counter">2</span> Course results matching
    </h3>

    <h4
      class="welcome-desc font-weight-normal mt-2 black container-narrow mx-auto"
    >
      Want to make your course results visible to companies that requires it?
    </h4>
    <div
      class="form-group custom-control custom-switch input-container d-flex align-items-center justify-content-center"
    >
      <p>Show course results</p>
      <label class="switch">
        <input type="checkbox" v-model="value.showGrades" />
        <span class="slider round"></span>
      </label>
    </div>
    <h3 class="mt-5 black" v-if="isBachelor">
      <span class="counter">2</span> Your Bachelor's degree courses
    </h3>
    <h3 class="mt-5 black" v-else>
      <span class="counter">3</span> Your Master's degree courses
    </h3>

    <h4
      class="welcome-desc font-weight-normal mt-2 black container-narrow mx-auto"
      v-if="isBachelor"
    >
      Fill in information about your Bachelor's degree courses. Upload your
      transcript of passed subject to autofill.
    </h4>
    <h4
      class="welcome-desc font-weight-normal mt-2 black container-narrow mx-auto"
      v-else
    >
      Fill in information about your Master's degree courses. Upload your
      transcript of passed subject to autofill.
    </h4>
    <div
      class="file-banner text-center text-black"
      v-if="value.hasUploadedTranscript"
    >
      <h1>File uploaded</h1>
      <h4 class="font-weight-normal">
        Data will be processed within 48h You will get an email when it is done.
      </h4>
      <div class="d-flex align-items-center justify-content-center">
        <p class="m-0">{{ value.transcriptName }}</p>
        <input
          type="button"
          class="btn pink-color"
          @click="removeFile"
          value="Remove"
        />
      </div>
    </div>
    <div v-else class="">
      <label for="file-upload" class="upload-button">Upload transcript</label>
      <input
        id="file-upload"
        type="file"
        style="display: none"
        @change="fileChanged"
      />
    </div>
    <div class="" v-show="isUploading">uploading...</div>
    <div class="" v-show="!hasSelectedFile && !value.hasUploadedTranscript">
      <h4 class="welcome-desc font-weight-normal mt-2 black" v-if="isBachelor">
        Or fill in your Bachelor's courses below.
      </h4>
      <h4 class="welcome-desc font-weight-normal mt-2 black" v-else>
        Or fill in your Master's courses below.
      </h4>
      <semester-item
        v-for="(item, index) in semesterCount"
        v-model="value"
        :key="index"
        :id="index + 1"
        :isBachelor="isBachelor"
        :semId="item"
        :universities="universities"
        :handleDelete="handleDelete"
        @add-course="addCourseItem"
      ></semester-item>
    </div>
    <div class="alert alert-danger mt-3" v-if="errorArray.length > 0">
      Please fill {{ errorArray.join(', ') }} for all semesters and courses.
    </div>
    <div class="d-flex justify-content-end mt-5 mb-5 inner-box">
      <div class="">
        <button
          class="btn btn-outline btn-new-outline RisingStar-StudyBackground-Skip w-200"
          @click="handleSkip"
        >
          Skip
        </button>
        <button
          class="btn btn-primary w-200 RisingStar-StudyBackground-Next"
          @click.prevent="validateForm"
        >
          Next
        </button>
      </div>
    </div>

    <div v-if="showGradesModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-body">
                  <h1>WELL THIS IS AWKWARD</h1>
                  <p>
                    We could have sworn you were someone who didn't want to miss
                    out on a collaboration opportunity. Our bad.
                    <br />
                    <br />
                    We'll just leave this button below if you change your mind
                    then...
                  </p>
                  <div
                    class="form-group custom-control custom-switch input-container d-flex align-items-center justify-content-center"
                  >
                    <p class="font-weight-bold text-white">Show grades</p>
                    <label class="switch">
                      <input type="checkbox" v-model="value.showGrades" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div class="text-right">
                    <button
                      class="btn white-button mt-3 Explorer-YourStudyBackground-Next w-200"
                      @click.prevent="handleGradesSubmit"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="showEctsModal">
      <transition name="modal">
        <div class="modal-mask" @click="showEctsModal = false">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <!-- <button
                  type="button"
                  class="close"
                  @click="showEctsModal = false"
                >
                  <span aria-hidden="true">&times;</span>
                </button> -->
                <div class="modal-body">
                  <h1>OOPS!</h1>
                  <p>
                    <Strong
                      >You are missing at least {{ totalPoints - sum }} ECTS
                      points</Strong
                    >
                    <br />
                    <br />
                    Please fill in all the ECTS points you have acquired on your
                    current study programme
                  </p>
                  <div class="text-right">
                    <button
                      class="btn white-button mt-3 w-200"
                      @click="showEctsModal = false"
                    >
                      Add more courses
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import json_semesters from '@/json/semesters.json';
import { mapState } from 'vuex';

import { HTTP } from '../../../axios/axios';

import firebase from 'firebase/app';
import 'firebase/storage';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import '@trevoreyre/autocomplete-vue/dist/style.css';
import SemesterItem from './SemesterItem.vue';
import RisingStarProgressBar from '../../RisingStarProgressBar.vue';

export default {
  components: { Autocomplete, RisingStarProgressBar, SemesterItem },
  props: [
    'value',
    'handleSubmit',
    'handleSkip',
    'errorText',
    'isUploading',
    'progress',
  ],
  data() {
    return {
      universityData: {},
      totalPoints: 0,
      sum: 0,
      semesters: json_semesters,
      loading: false,
      universityLoaded: false,
      hasSelectedFile: false,
      showEctsModal: false,
      file: null,
      errorArray: [],
      // years: [2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023],
      years: [
        2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021,
        2022, 2023,
      ],
      subProgrammeList: [],
      showGradesModal: false,
    };
  },
  mounted: function () {
    this.$store.dispatch('getUniversityData');
    // if (this.value.bachelorInstitution.uid) {
    if (this.value.institution.uid) {
      this.getCurrentUniversityData();
      this.loading = false;
    }
    if (this.value.educationHistory === null) {
      this.$store.dispatch('updateUserDetails', {
        ...this.value,
        educationHistory: {
          1: { 1: {} },
          2: { 1: {} },
          3: { 1: {} },
          4: { 1: {} },
          5: { 1: {} },
          6: { 1: {} },
          7: { 1: {} },
          8: { 1: {} },
          9: { 1: {} },
          10: { 1: {} },
        },
      });
    }
    // check if student is bachelor or master/candidate
  },
  computed: {
    ...mapState(['user']),
    isBachelor() {
      return this.value.currentDegree.includes('bachelor');
    },
    semesterCount() {
      // return this.value.degree.includes('Bachelor') ? 6 : 4;
      const arrLength = +this.value.currentSemester - 1;
      const count = [];
      for (let i = 1; i <= arrLength; i++) {
        count.push(i);
      }
      return count; // updated the logic here, so if a student is in 3rd sem, we show 2 semester items and so on
    },
    isLoaded() {
      var vm = this;
      var result = false;
      if (this.user.userId !== null) {
        var userInfo = JSON.parse(JSON.stringify(vm.$store.state.user));
        vm.value = userInfo;
      }
      return true;
    },
    universities: function () {
      return this.$store.getters.universities;
    },
  },
  methods: {
    fileChanged(event) {
      this.hasSelectedFile = true;
      this.file = event.target.files[0];
      // if(this.file.type)
      this.$emit('file-selected', this.file);
    },
    //this method will be removed in package 11
    async getCurrentUniversityData() {
      // this.value.secondaryArea = null;
      // this.value.area = null;
      // this.universityLoaded = true;
      try {
        const res = await HTTP.get(
          `getUniversityDataListing/${this.value.bachelorInstitution.uid}`
        );
        if (res.status == 200) {
          // no idea wtf this data is now. Random naming with random data is what we have here
          this.universityLoaded = true;
          this.universityData = res.data.payload;
        }
      } catch (error) {
        console.log('err');
      }
    },
    searchArea(input) {
      const vm = this;
      this.value.customProgramme = true;
      if (input.length < 1) {
        return [];
      }
      return this.universityData.programmes.filter((area) => {
        return area.programme_name.toLowerCase().includes(input.toLowerCase());
      });
    },
    getResultValue(result) {
      this.value.customProgramme = false;
      return result.programme_name;
    },
    // setAreaForUser(area) {
    //   this.value.hasBachelorSubProgramme = false;
    //   if (area['subProgrammesList']) {
    //     this.value.hasBachelorSubProgramme = true;
    //     this.subProgrammeList = area['subProgrammesList'];
    //   }
    //   // now we get the students degree details once they select an area
    //   this.value.degree = Object.values(this.universityData.degrees).filter(
    //     (item) => item.id === area.degree_id
    //   )[0].degree_name;
    //   this.value.area = area.programme_name;
    // },
    setBachelorAreaForUser(area) {
      this.value.hasBachelorSubProgramme = false;
      if (area['subProgrammesList']) {
        this.value.hasBachelorSubProgramme = true;
        this.subProgrammeList = area['subProgrammesList'];
      }
      // now we get the students degree details once they select an area
      this.value.bachelorDegree = Object.values(
        this.universityData.degrees
      ).filter((item) => item.id === area.degree_id)[0].degree_name;
      this.value.bachelorArea = area.programme_name;
    },
    // setAreaForUser(area) {
    //   this.value.area = area.programme_name;
    // },
    // setSecondaryAreaForUser(area) {
    //   this.value.secondaryArea = area.programme_name;
    // },
    onFileChange(event) {
      this.file = event.target.files[0];
    },
    async uploadFile() {
      if (!this.file) {
        return;
      }

      if (this.file.size > 20 * 1024 * 1024) {
        alert('File size exceeds 20 MB');
        return;
      }

      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`uploads/${this.file.name}`);
      await fileRef.put(this.file);

      // alert('File uploaded successfully');
    },
    setFirstSpecialization(area) {
      this.value.bachelorSpecializationCourseOne = area.subProgrammeName;
    },
    setSecondSpecialization(area) {
      this.value.bachelorSpecializationCourseTwo = area.subProgrammeName;
    },
    validateForm() {
      this.errorArray = [];
      this.showGradesModal = false;
      this.sum = 0;
      let totalDeduction = 0;
      if (this.value.hasUploadedTranscript && !this.value.showGrades) {
        this.showGradesModal = true;
        return;
      } else if (this.value.hasUploadedTranscript && this.value.showGrades) {
        this.handleSubmit('courses');
      }
      for (let key1 in this.value.educationHistory) {
        if (key1 > this.value.currentSemester - 1) {
          console.log(key1 > this.value.currentSemester - 1);
          break;
        }
        if (Object.keys(this.value.educationHistory[key1]).length === 0) {
          totalDeduction += 30;
        }
        for (let key2 in this.value.educationHistory[key1]) {
          if (this.value.educationHistory[key1][key2].ects) {
            this.sum += +this.value.educationHistory[key1][key2].ects;
          }
          if (!this.value.educationHistory[key1][key2].ects) {
            this.errorArray.push('ects');
          }
          if (!this.value.educationHistory[key1][key2].year) {
            this.errorArray.push('year');
          }
          if (!this.value.educationHistory[key1][key2].institution) {
            this.errorArray.push('institution');
          }
          if (!this.value.educationHistory[key1][key2].area) {
            this.errorArray.push('area');
          }
          if (!this.value.educationHistory[key1][key2].courseTitle) {
            this.errorArray.push('courseTitle');
          }
          if (!this.value.educationHistory[key1][key2].semester) {
            this.errorArray.push('semester');
          }
          if (!this.value.educationHistory[key1][key2].result) {
            this.errorArray.push('result');
          }
        }
      }
      this.totalPoints = +this.value.currentSemester - 1;
      this.totalPoints = this.totalPoints * 30;
      this.totalPoints = this.totalPoints - 15 - totalDeduction;
      if (this.errorArray.length > 0) {
        this.errorArray = [...new Set(this.errorArray)];
        return;
      }
      if (
        (!this.file && this.sum < this.totalPoints) ||
        this.totalPoints <= 0
      ) {
        this.showEctsModal = true;
        return;
      }
      if (!this.value.showGrades) {
        this.showGradesModal = true;
        return;
      }
      this.handleSubmit('courses');
    },
    handleGradesSubmit() {
      this.showGradesModal = false;
      this.handleSubmit('courses');
    },
    closeModal() {
      this.showGradesModal = false;
    },
    // addCourseItem(semester) {
    //   this.value.semesterCourseCount[semester] += 1;
    //   this.value.educationHistory[semester][
    //     this.value.semesterCourseCount[semester]
    //   ] = {};
    // },
    // handleDelete(semester, courseId) {
    //   this.value.semesterCourseCount[semester] -= 1;
    //   delete this.value.educationHistory[semester][courseId];
    // },
    // DONT DELETE
    addCourseItem(semester) {
      const newItem =
        this.value.semesterCourseCount[semester][
          this.value.semesterCourseCount[semester].length - 1
        ] + 1;
      if (!newItem) {
        this.value.semesterCourseCount[semester].push(1);
        this.value.educationHistory[semester][1] = {};
        return;
      }
      this.value.semesterCourseCount[semester].push(newItem);
      this.value.educationHistory[semester][newItem] = {};
    },
    handleDelete(semester, courseId) {
      this.value.semesterCourseCount[semester].splice(
        this.value.semesterCourseCount[semester].indexOf(courseId),
        1
      );
      delete this.value.educationHistory[semester][courseId];
    },
    // onFileChange(event) {
    //   this.file = event.target.files[0];
    // },
    // async uploadFile() {
    //   if (!this.file) {
    //     return;
    //   }

    //   if (this.file.size > 20 * 1024 * 1024) {
    //     alert('File size exceeds 20 MB');
    //     return;
    //   }

    //   const storageRef = firebase.storage().ref();
    //   const fileRef = storageRef.child(`uploads/${this.file.name}`);
    //   await fileRef.put(this.file);

    //   alert('File uploaded successfully');
    // },
    removeFile() {
      this.value.hasUploadedTranscript = false;
      this.hasSelectedFile = false;
      this.$emit('remove-file');
    },
  },
};
</script>

<style scoped>
.autocomplete-input {
  color: #495057 !important;
}
.autocomplete .autocomplete-result-list li {
  color: #495057 !important;
}
.file-banner {
  width: 493px;
  background: #f8f8f8;
  margin: 20px auto;
  padding: 5%;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-content {
  background: transparent linear-gradient(270deg, #bc1e73 0%, #5a2871 100%) 0%
    0% no-repeat padding-box;
  color: white;
  max-width: 500px;
  padding: 5%;
  border-radius: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #3aa948;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3aa948;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.custom-switch p {
  margin-bottom: 0;
  font-weight: 500 !important;
  color: #512871;
  margin: 0px 0px 7px !important;
}
.custom-switch {
  column-gap: 10px;
}
.inner-box {
  padding: 0px 30px;
}
.btn-new-outline {
  padding: 0.375rem 0.75rem !important;
  width: 200px;
  margin-right: 10px;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer !important;
}

.upload-button {
  color: white;
  margin: 15px 0;
  width: 350px;
  cursor: pointer !important;
  border: none;
  background-color: #5a2871;
  padding: 8px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
}

.upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer !important;
}
/* @media (min-width: 799px) {
.study-wrapper {
  margin-top: 50px;
}
} */

.counter {
  background-color: #bc1e73;
  border-radius: 50%;
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: white;
}
@media (max-width: 499px) {
  .counter {
    background-color: #bc1e73;
    border-radius: 50%;
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: white;
  }
  .file-banner {
    width: 100%;
  }
}
.white-button {
  background-color: white;
  color: #bc1e73;
}
.upload-btn {
  display: inline-block;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.upload-btn:hover {
  background-color: #3e8e41;
}
@media (max-width: 767px) {
  .mobile-text {
    margin-bottom: 55px;
  }
  .w-200 {
    /* width: auto */
    width: 120px;
  }
}
.text-white {
  color: white !important;
}
</style>
