<template>
  <div class="">
    <h1 class="text-uppercase common-text">YOUR TALENT BIO</h1>
    <rising-star-progress-bar
      :currentValue="progress"
    ></rising-star-progress-bar>
    <h4 class="welcome-desc font-weight-normal container-narrow mx-auto">
      Tell us a bit more about yourself to stand out from the crowd and attract
      the right companies.
    </h4>
    <h4 class="welcome-desc font-weight-normal mt-4 container-narrow mx-auto">
      Leave out your name, age, gender, and nationality to ensure unbiased
      matching.
    </h4>
    <form @submit.prevent="validateFields">
      <div class="form-group text-left mt-4">
        <label for="">Profile bio</label>
        <plain-text-editor
          placeholder="Describe yourself. What do you like to do in your free time? What are your interests and hobbies?"
          :limit="1000"
          v-model="value.profileBio"
        ></plain-text-editor>
        <div class="alert alert-danger mt-3" v-if="bioError">
          {{ bioError }}
        </div>
      </div>
      <div class="form-group text-left">
        <label for="">Passion and motivation</label>
        <plain-text-editor
          placeholder="What are you passionate about and what motivates you? Why are you the right person for a company?"
          :limit="1000"
          v-model="value.passionAndMotivation"
        ></plain-text-editor>
        <div class="alert alert-danger mt-3" v-if="motivationError">
          {{ motivationError }}
        </div>
      </div>
      <div class="alert alert-danger mt-3" v-if="errorText">
        {{ errorText }}
      </div>
      <div class="text-right mt-5">
        <!-- <button class="btn btn-link Explorer-YourStudyBackground-Back" @click.prevent="handleBack">
          <i class="fas fa-chevron-left"></i> Back
        </button> -->
        <button class="btn btn-outline mr-2 Talent-TalentBio-Skip w-200"  @click.prevent="handleSkip">Skip</button>
        <button class="btn btn-primary Talent-TalentBio-Next w-200">
          Next
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import plainTextEditor from '../../plainTextEditor.vue';
import RisingStarProgressBar from '../../RisingStarProgressBar.vue';
export default {
  components: { plainTextEditor, RisingStarProgressBar },
  props: ['value', 'handleSubmit', 'progress', 'handleBack', 'handleSkip'],
  data() {
    return {
      bioError: '',
      motivationError: '',
      errorText: '',
    };
  },
  methods: {
    validateFields() {
      const vm = this;
      vm.bioError = '';
      vm.motivationError = '';
      vm.errorText = '';
      if (!vm.value.profileBio) {
        vm.bioError = 'Please provide profile bio.';
      }
      if (!vm.value.passionAndMotivation) {
        vm.motivationError = 'Please provide passion and motivation.';
      }
      if (vm.value.profileBio.length < 20) {
        vm.bioError = 'Please provide more information for profile bio';
      }
      if (vm.value.passionAndMotivation.length < 20) {
        vm.motivationError =
          'Please provide more information for passion and motivation';
      }
      if (
        vm.value.passionAndMotivation.length > 2000 ||
        vm.value.profileBio.length > 2000
      ) {
        vm.errorText = 'Maximum 2000 characters allowed';
        return;
      }
      vm.handleSubmit('talent bio');
    },
  },
};
</script>

<style scoped>
form {
  padding: 0 !important;
}
@media (max-width: 767px) {
  .w-200 {
    /* width: 120px; */
    margin-top: 20px;
  }
  .btn-outline{
    padding-left: 0;
    padding-right: 0
  }
  .common-text {
    font-size: 24px;
    margin-bottom: 50px;
  }
}
</style>
